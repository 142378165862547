import React, { useState } from "react";
import { Box, Tab, Tabs, Container } from "@mui/material";
import RealTimeChart from "../../components/LineChart";
import ThermostatIcon from '@mui/icons-material/Thermostat';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import InsightsIcon from '@mui/icons-material/Insights';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import WaterOutlinedIcon from '@mui/icons-material/WaterOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import Header from "../../components/Header";
import TempData from "../../data/tempData";
import HumidityData from "../../data/humidityData";
import TdsData from "../../data/tdsData";
import LightData from "../../data/lightData";
import WaterLvlData from "../../data/waterLvlData";
import PhData from "../../data/phData";
import BarChart from "../../components/BarChart";

const tabIcons = [
  <ThermostatIcon />,
  <WaterDropIcon />,
  <InsightsIcon />,
  <LightModeOutlinedIcon />,
  <WaterOutlinedIcon />,
  <SpeedOutlinedIcon />
];

const tabLabels = ["Temperature", "Humidity", "Tds", "Light", "WaterLvl", "Ph"];

const tabDataComponents = [
  <TempData />,
  <HumidityData />,
  <TdsData />,
  <LightData />,
  <WaterLvlData />,
  <PhData />
];

const DailyStatus= () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const activeIconName = tabLabels[activeTab]; // Get the active tab label

  return (
    <Box m="10px" marginLeft="90px">
      <Box
        display="grid"
        gridTemplateColumns="repeat(8, 1fr)"
        gap="10px"
      >
        <Box
          gridColumn="span 8"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Container>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              indicatorColor="secondary"
              textColor="secondary"
              variant="fullWidth"
            >
              <Tab icon={tabIcons[0]} sx={{ minWidth: "40px"}} />
              <Tab icon={tabIcons[1]} sx={{ minWidth: "40px"}} />
              <Tab icon={tabIcons[2]} sx={{ minWidth: "40px"}} />
              <Tab icon={tabIcons[3]} sx={{ minWidth: "40px"}} />
              <Tab icon={tabIcons[4]} sx={{ minWidth: "40px"}} />
              <Tab icon={tabIcons[5]} sx={{ minWidth: "40px"}} />
            </Tabs>
          </Container>
        </Box>
        <Box
          gridColumn="span 8"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box sx={{ height: "60px" }}>
            <Header title="Daily Status" subtitle={activeIconName} />
          </Box>
        </Box>
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          sx={{ overflowX: "auto", overflowY: "hidden" }}
        >
          <Box
            mt="25px"
            p="0 30px"
            justifyContent="space-between"
            alignItems="center"
            
          />
          <Box height="300px" width="2000px" ml="-10px" mt="-60px">
            <BarChart chartType={activeIconName} />
          </Box>
        </Box>
        <Box
          gridColumn="span 8"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {tabDataComponents[activeTab]}
        </Box>
      </Box>
    </Box>
  );
};

export default DailyStatus;
