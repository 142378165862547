import React, { useState, useEffect } from 'react';
import { database } from '../../components/firebaseConfig';
import { onValue, ref, set } from 'firebase/database';
import { IconButton, Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import ThermostatIcon from '@mui/icons-material/Thermostat';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import InsigthsIcon from '@mui/icons-material/Insights';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import WaterOutlinedIcon from '@mui/icons-material/WaterOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import GaugeBar from '../../components/GaugeBar';


const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [data, setData] = useState(null); 
  const [autoData, setAutoData] = useState(null); 
  const [autoDataMax, setAutoDataMax] = useState(null); 

  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.toLocaleString('default', { month: 'long' });
    const day = currentDate.getDate().toString().padStart(2, '0');

    const dataRef = ref(database, `${year}/${month}/${day}`);

    const unsubscribe = onValue(dataRef, (snapshot) => {
      if (snapshot.exists()) {
        const dayData = snapshot.val();
        const latestTimestamp = Object.keys(dayData).pop();
        const latestData = dayData[latestTimestamp];

        setData(latestData);
      } else {
        // Handle the case where no data exists for the given day
        setData(null);
      }
    });

    // Return a cleanup function to unsubscribe from the Firebase listener when the component unmounts
    return () => unsubscribe();
  }, []);

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Update every 1 second

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const autoDataRef = ref(database, 'AutomationMin'); // Adjust the path as needed

    onValue(autoDataRef, (snapshot) => {
      if (snapshot.exists()) {
        const autoData = snapshot.val();
        setAutoData(autoData);
      }
    });
  }, []);

  useEffect(() => {
    const autoDataRefMax = ref(database, 'AutomationMax'); // Adjust the path as needed

    onValue(autoDataRefMax, (snapshot) => {
      if (snapshot.exists()) {
        const autoDataMax = snapshot.val();
        setAutoDataMax(autoDataMax);
      }
    });
  }, []);

  const increaseTemperatureMin = () => {
    if (autoData) {
      const updatedTemperature = autoData.Temperature + 1;
      set(ref(database, 'AutomationMin/Temperature'), updatedTemperature);
    }
  };
  const decreaseTemperatureMin = () => {
    if (autoDataMax) {
      const updatedTemperature = autoData.Temperature - 1;
      set(ref(database, 'AutomationMin/Temperature'), updatedTemperature);
    }
  };
  const increaseHumidity = () => {
    if (autoData) {
      const updatedHumidity = autoData.Humidity + 1;
      set(ref(database, 'AutomationMin/Humidity'), updatedHumidity);
    }
  };
  const decreaseHumidity = () => {
    if (autoData) {
      const updatedHumidity = autoData.Humidity - 1;
      set(ref(database, 'AutomationMin/Humidity'), updatedHumidity);
    }
  };
  const increaseTds = () => {
    if (autoData) {
      const updatedHumidity = autoData.Tds + 1;
      set(ref(database, 'AutomationMin/Tds'), updatedHumidity);
    }
  };
  const decreaseTds = () => {
    if (autoData) {
      const updatedHumidity = autoData.Tds - 1;
      set(ref(database, 'AutomationMin/Tds'), updatedHumidity);
    }
  };
  const increasePh = () => {
    if (autoData) {
      const updatedHumidity = autoData.Ph + 0.5;
      set(ref(database, 'AutomationMin/Ph'), updatedHumidity);
    }
  };
  const decreasePh = () => {
    if (autoData) {
      const updatedHumidity = autoData.Ph - 0.5;
      set(ref(database, 'AutomationMin/Ph'), updatedHumidity);
    }
  };
  const increaseLight = () => {
    if (autoData) {
      const updatedHumidity = autoData.Light + 1;
      set(ref(database, 'AutomationMin/Light'), updatedHumidity);
    }
  };
  const decreaseLight = () => {
    if (autoData) {
      const updatedHumidity = autoData.Light - 1;
      set(ref(database, 'AutomationMin/Light'), updatedHumidity);
    }
  };
  const increaseWaterLvl = () => {
    if (autoData) {
      const updatedHumidity = autoData.WaterLvl + 1;
      set(ref(database, 'AutomationMin/WaterLvl'), updatedHumidity);
    }
  };
  const decreaseWaterLvl = () => {
    if (autoData) {
      const updatedHumidity = autoData.WaterLvl - 1;
      set(ref(database, 'AutomationMin/WaterLvl'), updatedHumidity);
    }
  };

  const increaseTemperatureMax = () => {
    if (autoDataMax) {
      const updatedTemperature = autoDataMax.Temperature + 1;
      set(ref(database, 'AutomationMax/Temperature'), updatedTemperature);
    }
  };
  const decreaseTemperatureMax = () => {
    if (autoDataMax) {
      const updatedTemperature = autoDataMax.Temperature - 1;
      set(ref(database, 'AutomationMax/Temperature'), updatedTemperature);
    }
  };
  const increaseHumidityMax = () => {
    if (autoDataMax) {
      const updatedTemperature = autoDataMax.Humidity + 1;
      set(ref(database, 'AutomationMax/Humidity'), updatedTemperature);
    }
  };
  const decreaseHumidityMax = () => {
    if (autoDataMax) {
      const updatedTemperature = autoDataMax.Humidity - 1;
      set(ref(database, 'AutomationMax/Humidity'), updatedTemperature);
    }
  };
  const increaseTdsMax = () => {
    if (autoDataMax) {
      const updatedTemperature = autoDataMax.Tds + 1;
      set(ref(database, 'AutomationMax/Tds'), updatedTemperature);
    }
  };
  const decreaseTdsMax = () => {
    if (autoDataMax) {
      const updatedTemperature = autoDataMax.Tds - 1;
      set(ref(database, 'AutomationMax/Tds'), updatedTemperature);
    }
  };
  const increasePhMax = () => {
    if (autoDataMax) {
      const updatedTemperature = autoDataMax.Ph + 0.5;
      set(ref(database, 'AutomationMax/Ph'), updatedTemperature);
    }
  };
  const decreasePhMax = () => {
    if (autoDataMax) {
      const updatedTemperature = autoDataMax.Ph - 0.5;
      set(ref(database, 'AutomationMax/Ph'), updatedTemperature);
    }
  };
  const increaseLightMax = () => {
    if (autoDataMax) {
      const updatedTemperature = autoDataMax.Light + 1;
      set(ref(database, 'AutomationMax/Light'), updatedTemperature);
    }
  };
  const decreaseLightMax = () => {
    if (autoDataMax) {
      const updatedTemperature = autoDataMax.Light - 1;
      set(ref(database, 'AutomationMax/Light'), updatedTemperature);
    }
  };
  const increaseWaterLvlMax = () => {
    if (autoDataMax) {
      const updatedTemperature = autoDataMax.WaterLvl + 1;
      set(ref(database, 'AutomationMax/WaterLvl'), updatedTemperature);
    }
  };
  const decreaseWaterLvlMax = () => {
    if (autoDataMax) {
      const updatedTemperature = autoDataMax.WaterLvl - 1;
      set(ref(database, 'AutomationMax/WaterLvl'), updatedTemperature);
    }
  };
  
  return (
    <Box m="10px" marginLeft="90px" alignItems="center" justifyContent="center">
      {/* HEADER */}
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: "40px",}}>
        <Header title="SMART FARMING"/>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: "40px",}}>
        <Header subtitle="Set Optimum Value Range"/>
      </Box>
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        ml="70px"
        mr="70px"
        mb="10px"
        sx={{ height: "30px",color: `${colors.grey[200]}`, backgroundColor: `${colors.primary[800]}`}}
      >
        <h1>{`${data ? data.Time : 'N/A'}`}</h1>
        {/* <h2>{currentTime.toLocaleTimeString()}</h2> */}
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(8, 1fr)"
        // gridAutoRows="140px"
        gap="10px"
      >
        {/* ROW 1 */}
        <Box
            gridColumn="span 2"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="100px"
            sx={{ backgroundColor:colors.blueAccent[800] }}
        >
            <IconButton onClick={increaseTemperatureMin}>
            <ArrowDropUpOutlinedIcon />
            </IconButton>
            <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ color: "#f1807e" }}
            >
            MIN
            </Typography>
            <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.greenAccent[500] }}
            >
            {`${autoData ? autoData.Temperature+' °C' : 'N/A'}`}
            </Typography>
            <IconButton onClick={decreaseTemperatureMin}>
            <ArrowDropDownOutlinedIcon />
            </IconButton>
        </Box>
        <Box
          gridColumn="span 4"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100px"
          sx={{ backgroundColor:colors.blueAccent[700] }}
        >
          <StatBox
            title={`${data ? data.Temperature : 'N/A'}`}
            subtitle={`Temperature`}
            progress={`${data ? data.Temperature/100 : 'N/A'}`}
            increase="°C"
            icon={
              <ThermostatIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
            gridColumn="span 2"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="100px"
            sx={{ backgroundColor:colors.blueAccent[800] }}
        >
            <IconButton onClick={increaseTemperatureMax}>
            <ArrowDropUpOutlinedIcon />
            </IconButton>
            <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ color: "#f1807e" }}
            >
            MAX
            </Typography>
            <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.greenAccent[500] }}
            >
            {`${autoData ? autoDataMax.Temperature+' °C' : 'N/A'}`}
            </Typography>
            <IconButton onClick={decreaseTemperatureMax}>
            <ArrowDropDownOutlinedIcon />
            </IconButton>
        </Box>
        <Box
            gridColumn="span 8"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="50px"
        >
          <GaugeBar chartType="Temperature"/>  {/* {chart} */}
        </Box>
        <Box
            gridColumn="span 2"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="100px"
            sx={{ backgroundColor:colors.blueAccent[800] }}
        >
            <IconButton onClick={increaseHumidity}>
            <ArrowDropUpOutlinedIcon />
            </IconButton>
            <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ color: "#f1807e" }}
            >
            MIN
            </Typography>
            <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.greenAccent[500] }}
            >
            {`${autoData ? autoData.Humidity+' %' : 'N/A'}`}
            </Typography>
            <IconButton onClick={decreaseHumidity}>
            <ArrowDropDownOutlinedIcon />
            </IconButton>
        </Box>
        <Box
          gridColumn="span 4"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100px"
          sx={{ backgroundColor:colors.blueAccent[700] }}
        >
          <StatBox
            title={`${data ? data.Humidity : 'N/A'}`}
            subtitle={`Humidity`}
            progress={`${data ? data.Humidity/100 : 'N/A'}`}
            increase="%"
            icon={
              <WaterDropIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        
        <Box
            gridColumn="span 2"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="100px"
            sx={{ backgroundColor:colors.blueAccent[800] }}
        >
            <IconButton onClick={increaseHumidityMax}>
            <ArrowDropUpOutlinedIcon />
            </IconButton>
            <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ color: "#f1807e" }}
            >
            MAX
            </Typography>
            <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.greenAccent[500] }}
            >
            {`${autoData ? autoDataMax.Humidity+' %' : 'N/A'}`}
            </Typography>
            <IconButton onClick={decreaseHumidityMax}>
            <ArrowDropDownOutlinedIcon />
            </IconButton>
        </Box>
        <Box
            gridColumn="span 8"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="50px"
        >
          <GaugeBar chartType="Humidity"/>  {/* {chart} */}
        </Box>
        <Box
            gridColumn="span 2"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="100px"
            sx={{ backgroundColor:colors.blueAccent[800] }}
        >
            <IconButton onClick={increaseTds}>
            <ArrowDropUpOutlinedIcon />
            </IconButton>
            <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ color: "#f1807e" }}
            >
            MIN
            </Typography>
            <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.greenAccent[500] }}
            >
            {`${autoData ? autoData.Tds+'' : 'N/A'}`}
            </Typography>
            <IconButton onClick={decreaseTds}>
            <ArrowDropDownOutlinedIcon />
            </IconButton>
        </Box>
        <Box
          gridColumn="span 4"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100px"
          sx={{ backgroundColor:colors.blueAccent[700] }}
        >
          <StatBox
            title={`${data ? data.Tds : 'N/A'}`}
            subtitle={`TDS`}
            progress={`${data ? data.Tds/1000 : 'N/A'}`}
            increase="ppm"
            icon={
              <InsigthsIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
            gridColumn="span 2"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="100px"
            sx={{ backgroundColor:colors.blueAccent[800] }}
        >
            <IconButton onClick={increaseTdsMax}>
            <ArrowDropUpOutlinedIcon />
            </IconButton>
            <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ color: "#f1807e" }}
            >
            MAX
            </Typography>
            <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.greenAccent[500] }}
            >
            {`${autoData ? autoDataMax.Tds+'' : 'N/A'}`}
            </Typography>
            <IconButton onClick={decreaseTdsMax}>
            <ArrowDropDownOutlinedIcon />
            </IconButton>
        </Box>
        <Box
            gridColumn="span 8"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="50px"
        >
          <GaugeBar chartType="Tds"/>  {/* {chart} */}
        </Box>
        <Box
            gridColumn="span 2"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="100px"
            sx={{ backgroundColor:colors.blueAccent[800] }}
        >
            <IconButton onClick={increasePh}>
            <ArrowDropUpOutlinedIcon />
            </IconButton>
            <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ color: "#f1807e" }}
            >
            MIN
            </Typography>
            <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.greenAccent[500] }}
            >
            {`${autoData ? autoData.Ph+' ' : 'N/A'}`}
            </Typography>
            <IconButton onClick={decreasePh}>
            <ArrowDropDownOutlinedIcon />
            </IconButton>
        </Box>
        <Box
          gridColumn="span 4"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100px"
          sx={{ backgroundColor:colors.blueAccent[700] }}
        >
          <StatBox
            title={`${data ? data.Ph : 'N/A'}`}
            subtitle={`PH value`}
            progress={`${data ? data.Ph/14 : 'N/A'}`}
            increase=""
            icon={
              <SpeedOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
            gridColumn="span 2"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="100px"
            sx={{ backgroundColor:colors.blueAccent[800] }}
        >
            <IconButton onClick={increasePhMax}>
            <ArrowDropUpOutlinedIcon />
            </IconButton>
            <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ color: "#f1807e" }}
            >
            MAX
            </Typography>
            <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.greenAccent[500] }}
            >
            {`${autoData ? autoDataMax.Ph+'' : 'N/A'}`}
            </Typography>
            <IconButton onClick={decreasePhMax}>
            <ArrowDropDownOutlinedIcon />
            </IconButton>
        </Box>
        <Box
            gridColumn="span 8"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="50px"
        >
          <GaugeBar chartType="Ph"/>  {/* {chart} */}
        </Box>
        <Box
            gridColumn="span 2"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="100px"
            sx={{ backgroundColor:colors.blueAccent[800] }}
        >
            <IconButton onClick={increaseLight}>
            <ArrowDropUpOutlinedIcon />
            </IconButton>
            <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ color: "#f1807e" }}
            >
            MIN
            </Typography>
            <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.greenAccent[500] }}
            >
            {`${autoData ? autoData.Light+' ix' : 'N/A'}`}
            </Typography>
            <IconButton onClick={decreaseLight}>
            <ArrowDropDownOutlinedIcon />
            </IconButton>
        </Box>
        <Box
          gridColumn="span 4"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100px"
          sx={{ backgroundColor:colors.blueAccent[700] }}
        >
          <StatBox
            title={`${data ? data.Light : 'N/A'}`}
            subtitle={`Light`}
            progress={`${data ? data.Light/1000 : 'N/A'}`}
            increase="ix"
            icon={
              <LightModeOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
            gridColumn="span 2"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="100px"
            sx={{ backgroundColor:colors.blueAccent[800] }}
        >
            <IconButton onClick={increaseLightMax}>
            <ArrowDropUpOutlinedIcon />
            </IconButton>
            <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ color: "#f1807e" }}
            >
            MAX
            </Typography>
            <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.greenAccent[500] }}
            >
            {`${autoData ? autoDataMax.Light+' ix' : 'N/A'}`}
            </Typography>
            <IconButton onClick={decreaseLightMax}>
            <ArrowDropDownOutlinedIcon />
            </IconButton>
        </Box>
        <Box
            gridColumn="span 8"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="50px"
        >
          <GaugeBar chartType="Light"/>  {/* {chart} */}
        </Box>
        <Box
            gridColumn="span 2"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="100px"
            sx={{ backgroundColor:colors.blueAccent[800] }}
        >
            <IconButton onClick={increaseWaterLvl}>
            <ArrowDropUpOutlinedIcon />
            </IconButton>
            <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ color: "#f1807e" }}
            >
            MIN
            </Typography>
            <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.greenAccent[500] }}
            >
            {`${autoData ? autoData.WaterLvl+' cm' : 'N/A'}`}
            </Typography>
            <IconButton onClick={decreaseWaterLvl}>
            <ArrowDropDownOutlinedIcon />
            </IconButton>
        </Box>
        <Box
          gridColumn="span 4"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100px"
          sx={{ backgroundColor:colors.blueAccent[700] }}
        >
          <StatBox
            title={`${data ? data.WaterLvl : 'N/A'}`}
            subtitle={`Water Lvl`}
            progress={`${data ? data.WaterLvl/20 : 'N/A'}`}
            increase="cm"
            icon={
              <WaterOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
            gridColumn="span 2"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="100px"
            sx={{ backgroundColor:colors.blueAccent[800] }}
        >
            <IconButton onClick={increaseWaterLvlMax}>
            <ArrowDropUpOutlinedIcon />
            </IconButton>
            <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ color: "#f1807e" }}
            >
            MAX
            </Typography>
            <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.greenAccent[500] }}
            >
            {`${autoData ? autoDataMax.WaterLvl+'cm' : 'N/A'}`}
            </Typography>
            <IconButton onClick={decreaseWaterLvlMax}>
            <ArrowDropDownOutlinedIcon />
            </IconButton>
        </Box>
        <Box
            gridColumn="span 8"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="50px"
        >
          <GaugeBar chartType="WaterLvl"/>  {/* {chart} */}
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
