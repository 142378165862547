import React, { useState, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { database } from './firebaseConfig';
import { useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../theme";
// import { mockBarData as data } from "../data/mockData";

const chartTitles = {
  Temperature: 'Temperature (°C)',
  Humidity: 'Humidity (%)',
  Tds: 'TDS (ppm)',
  Ph: 'PH (PHvalue)',
  Light: 'Light (ix)',
  WaterLvl: 'Water Level (cm)',
};

const BarChart = ({ chartType, isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.toLocaleString('default', { month: 'long' });
    const day = currentDate.getDate().toString().padStart(2, '0');
  
    const folderRef = ref(database, `${year}/${month}/${day}`);
    const dataRef = onValue(folderRef, (snapshot) => {
      if (snapshot.exists()) {
        const folderData = snapshot.val();
        const newData = Object.keys(folderData).map((timeKey, index) => ({
          x: folderData[timeKey].Time, // x-value based on time
          y: parseFloat(folderData[timeKey][chartType]) || 0,
          // color: 'hsl(229, 70%, 50%)',
        }));
  
        newData.reverse();
        setData(newData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  
    return () => dataRef();
  }, [chartType]);

  // console.log(data)
  const chartTitle = chartTitles[chartType] || chartType;

  return (
    <ResponsiveBar
      data={data}
      theme={{
        // added
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
        tooltip: {
          container: {
            color: colors.primary[950], // Adjust the color here
            background: colors.primary[50],
          },
        },
      }}
      keys={["y"]}
      indexBy="x"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.2}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "nivo" }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      borderColor={{
        from: "color",
        modifiers: [["darker", "1.6"]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 4,
        tickPadding:1,
        tickRotation: -90,
        legend: isDashboard ? undefined : "", // changed
        legendPosition: "middle",
        legendOffset: 32,
        tickValues: data.map((item, index) => (index % 5 === 0 ? item.x : "")),
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : chartTitle, // changed
        legendPosition: "middle",
        legendOffset: -40,
      }}
      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      barAriaLabel={function (e) {
        return e.id + ": " + e.formattedValue + " in country: " + e.indexValue;
      }}
    />
  );
};

export default BarChart;
