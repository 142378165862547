import React, { useState, useEffect } from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ref, onValue } from "firebase/database";
import { database } from '../components/firebaseConfig';
import { Box } from '@mui/material';

export default function TdsData() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.toLocaleString('default', { month: 'long' });
  const day = currentDate.getDate().toString().padStart(2, '0');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [autoData, setAutoData] = useState(null);
  const [autoDataMax, setAutoDataMax] = useState(null);

  useEffect(() => {

    const folderRef = ref(database, `${year}/${month}/${day}`); // Use monthName

    const unsubscribe = onValue(folderRef, (snapshot) => {
      if (snapshot.exists()) {
        const folderData = snapshot.val();

        const newData = Object.keys(folderData).map((timeKey, index) => {
          const tds = folderData[timeKey].Tds;
          return {
            id: index,
            time: folderData[timeKey].Time,
            tds,
          };
        });

        newData.reverse();
        setData(newData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });

    // Cleanup function to unsubscribe
    return () => unsubscribe();
  }, [year, month, day]);
  
  useEffect(() => {
    const autoDataRef = ref(database, 'AutomationMin'); // Adjust the path as needed
    const autoDataRefMax = ref(database, 'AutomationMax'); // Adjust the path as needed

    onValue(autoDataRefMax, (snapshot) => {
      if (snapshot.exists()) {
        const autoDataMax = snapshot.val();
        setAutoDataMax(autoDataMax);
      }
    });

    onValue(autoDataRef, (snapshot) => {
      if (snapshot.exists()) {
        const autoData = snapshot.val();
        setAutoData(autoData);
      }
    });
  }, []);

  return (
    <Box>
      {/* <h1>Daily Status</h1> */}
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Time</TableCell>
            <TableCell align='center'>TDS</TableCell>
            <TableCell align="right">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow 
            key={row.id} 
            style={{
              backgroundColor: row.tds < autoData.Tds ? '#FF9999' : row.tds > autoDataMax.Tds ? '#fa8072' : 'secondary',
            }}
          >          
              <TableCell>{row.time}</TableCell>
              <TableCell align='center'>{row.tds}{'ppm'}</TableCell>
              <TableCell align="right">
              {row.tds < autoData.Tds ? 'Low' : row.tds > autoDataMax.Tds ? 'High' : 'Good'}
              </TableCell>
            </TableRow>
          ))}
          {loading && (
            <TableRow>
              <TableCell colSpan={3} align="center">
                Loading real-time data...
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Link color="primary" href="#" onClick={(event) => event.preventDefault()} sx={{ mt: 3 }}>
        Download as excel
      </Link>
    </Box>
  );
}
