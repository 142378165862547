import { Box, Icon, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";

const StatBox = ({ title, subtitle, icon, progress, increase }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const angle = progress * 360;

  return (
    <Box width="100%" m="0 40px">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh" // This is just for demo purposes to simulate a parent container height
      >
        <Box
          sx={{
            // background: `radial-gradient(${colors.primary[400]} 55%, transparent 56%),
            //     conic-gradient(transparent 0deg ${angle}deg, ${colors.blueAccent[500]} ${angle}deg 360deg),
            //     ${colors.greenAccent[500]}`,
            borderRadius: "50%",
            width: `120px`,
            height: `120px`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon
            sx={{
              fontSize: "48px",
            }}
          >
            {icon}
          </Icon>

          <Box
            display="flex"
            flexDirection="row" // Display "title" and "increase" components in a row
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ color: colors.grey[100] }}
            >
              {title}
            </Typography>
            <Typography
              variant="h5"
              fontStyle="bold"
              sx={{ color: colors.blueAccent[200] }}
            >
              {increase}
            </Typography>
          </Box>

          <Typography variant="h5" sx={{ color: colors.greenAccent[200] }} marginBottom="30px">
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default StatBox;
