import React, { useState, useEffect } from 'react';
import { IgrLinearGauge, IgrLinearGraphRange } from 'igniteui-react-gauges';
import { IgrLinearGaugeModule } from 'igniteui-react-gauges';
import { ref, onValue } from 'firebase/database';
import { database } from './firebaseConfig';
import { Box, useTheme } from '@mui/material';
import { tokens } from '../theme';

IgrLinearGaugeModule.register();

const chartTitles = {
  Temperature: 'Temperature (°C)',
  Humidity: 'Humidity (%)',
  Tds: 'TDS (ppm)',
  Ph: 'PH (PHvalue)',
  Light: 'Light (ix)',
  WaterLvl: 'Water Level (cm)',
};

export default function GaugeBar({ chartType }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [autoData, setAutoData] = useState({});
  const [autoDataMax, setAutoDataMax] = useState({});

  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.toLocaleString('default', { month: 'long' });
    const day = currentDate.getDate().toString().padStart(2, '0');

    const dataRef = ref(database, `${year}/${month}/${day}`);

    const unsubscribe = onValue(dataRef, (snapshot) => {
      if (snapshot.exists()) {
        const dayData = snapshot.val();
        const latestTimestamp = Object.keys(dayData).pop();
        const latestData = dayData[latestTimestamp];

        setData(latestData);
      } else {
        setData(null);
      }

      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const autoDataRef = ref(database, 'AutomationMin'); // Adjust the path as needed

    onValue(autoDataRef, (snapshot) => {
      if (snapshot.exists()) {
        const autoData = snapshot.val();
        setAutoData(autoData);
      }
    });
  }, []);

  useEffect(() => {
    const autoDataRefMax = ref(database, 'AutomationMax'); // Adjust the path as needed

    onValue(autoDataRefMax, (snapshot) => {
      if (snapshot.exists()) {
        const autoDataMax = snapshot.val();
        setAutoDataMax(autoDataMax);
      }
    });
  }, []);

  const dataValue = data ? data[chartType] : 0;
  const markerValue = autoData[chartType] ?? 0;
  const markerMaxValue = autoDataMax[chartType] ?? 0;
  let minVal = 0;
  let maxVal = 60;

  if (chartType === 'Temperature') {
    minVal = 0;
    maxVal = 60;
  } else if (chartType === 'Humidity') {
    minVal = 50;
    maxVal = 100;
  } else if (chartType === 'Tds') {
    minVal = 800;
    maxVal = 1000;
  }else if (chartType === 'Ph') {
    minVal = 0;
    maxVal = 14;
  }else if (chartType === 'Light') {
    minVal = 50;
    maxVal = 100;
  }else if (chartType === 'WaterLvl') {
    minVal = 0;
    maxVal = 15;
  }

  return (
    <Box>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <IgrLinearGauge
            height="40px" width="250px"
            backingBrush={colors.primary[950]}
            backingOutline={colors.primary[950]}
            tickBrush={colors.primary[50]}
            minorTickBrush={colors.primary[50]}
            fontBrush={colors.blueAccent[200]}
            needleBrush={colors.blueAccent[600]}
            needleOutline={colors.grey[900]}
            minimumValue={minVal} value={dataValue}
            maximumValue={maxVal} interval={(maxVal-minVal)/5}
            rangeBrushes={`#f1807e, ${colors.greenAccent[300]}`}
            rangeOutlines={`#f1807e, ${colors.greenAccent[300]}`} >
            <IgrLinearGraphRange
                key="range1"
                startValue={minVal} endValue={markerValue}
                innerStartExtent={0.075} innerEndExtent={0.075}
                outerStartExtent={0.5} outerEndExtent={0.5} />
            <IgrLinearGraphRange
                key="range2"
                startValue={markerValue} endValue={markerMaxValue}
                innerStartExtent={0.075} innerEndExtent={0.075}
                outerStartExtent={0.5} outerEndExtent={0.5} />
            <IgrLinearGraphRange
                key="range3"
                startValue={markerMaxValue} endValue={maxVal}
                innerStartExtent={0.075} innerEndExtent={0.075}
                outerStartExtent={0.5} outerEndExtent={0.5} />
        </IgrLinearGauge>
      )}
    </Box>
  );
}
