import React, { useState, useEffect } from 'react';
import { database } from '../../components/firebaseConfig';
import { onValue, ref, set } from 'firebase/database';
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import Header from "../../components/Header";
import GeographyChart from "../../components/GeographyChart";
import StatBox from "../../components/StatBox";
import ThermostatIcon from '@mui/icons-material/Thermostat';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import InsigthsIcon from '@mui/icons-material/Insights';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import WaterOutlinedIcon from '@mui/icons-material/WaterOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import Switch from '@mui/material/Switch';

const Device = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [data, setData] = useState(null);

  const [deviceData, setDeviceData] = useState([]);
  const [checked, setChecked] = useState({});

  useEffect(() => {
    const deviceDataRef = ref(database, 'Device'); // Adjust the path as needed

    onValue(deviceDataRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setDeviceData(data);

        // Initialize the checked state based on the keys of your devices
        const initialChecked = {};
        Object.keys(data).forEach((key) => {
          initialChecked[key] = data[key];
        });
        setChecked(initialChecked);
      }
    });
  }, []);

  const handleChange = (key, isChecked) => {
    setChecked({ ...checked, [key]: isChecked });

    // Update the corresponding data in Firebase
    const dataRef = ref(database, `Device/${key}`); // Adjust the path as needed
    set(dataRef, isChecked);
  };

  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.toLocaleString('default', { month: 'long' });
    const day = currentDate.getDate().toString().padStart(2, '0');

    const dataRef = ref(database, `${year}/${month}/${day}`); // Replace with the actual path to your data

    const unsubscribe = onValue(dataRef, (snapshot) => {
      if (snapshot.exists()) {
        const dayData = snapshot.val();
        // You may need to iterate through the timestamp keys to get the latest data
        const latestTimestamp = Object.keys(dayData).pop();
        const latestData = dayData[latestTimestamp];

        setData(latestData);
      } else {
        // Handle the case where no data exists for the given day
        setData(null);
      }
    });

    // Return a cleanup function to unsubscribe from the Firebase listener when the component unmounts
    return () => unsubscribe();
  }, []);

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Update every 1 second

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  
  return (
    <Box m="10px" marginLeft="90px" alignItems="center" justifyContent="center">
      {/* HEADER */}
      
        
        
        <Box
          gridColumn="span 8"
          gridRow="span 4"
          backgroundColor={colors.primary[400]}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Devices Controller
            </Typography>
          </Box>
          {Object.keys(deviceData).map((key) => (
            <Box
              key={key}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {key}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {deviceData[key] ? 'ON' : 'OFF'}
                </Typography>
              </Box>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>{checked[key] ? 'ON' : 'OFF'}</div>
                  <Switch
                    checked={checked[key]}
                    onChange={() => handleChange(key, !checked[key])}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </div>
              </Box>
            </Box>
          ))}
        </Box>

        {/* <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          padding="30px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ marginBottom: "15px" }}
          >
            Geography Based Traffic
          </Typography>
          <Box height="200px">
            <GeographyChart isDashboard={true} />
          </Box>
        </Box> */}
      </Box>
  );
};

export default Device;
