import * as React from 'react';
import { database } from '../../components/firebaseConfig';
import { ref, set, get, update, remove } from "firebase/database";
// import './index.css';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/material';

class CrudSetup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      db: null,
      time: '',
      temperature: '',
      humidity: '',
      tds: '',
      ph: '',
      light: '',
      waterLvl: '',
      generatorIntervalId: null,
    };
    this.interface = this.interface.bind(this);
  }

  componentDidMount() {
    this.setState({
      db: database
    });
  }

  getCurrentDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    // Add 1 to the month because it is zero-based (0 = January, 1 = February, ...)
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate().toString().padStart(2, '0');
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const seconds = currentDate.getSeconds().toString().padStart(2, '0');
    return { year, month, day, hours, minutes, seconds};
  }

  

  render() {
    return (
      <>
        <label>Enter Time</label>
        <input type='text' id="timebox" value={this.state.time}
          onChange={e => { this.setState({ time: e.target.value }); }} />
        <br></br>
        <p> ^above only to get data or update</p>
        <br></br>

        <label>Enter Soil Temperature</label>
        <input type='number' id="temperaturebox" value={this.state.temperature}
          onChange={e => { this.setState({ temperature: e.target.value }); }} />
        <br></br>

        <label>Enter Soil Moisture</label>
        <input type='number' id="humiditybox" value={this.state.humidity}
          onChange={e => { this.setState({ moisture: e.target.value }); }} />
        <br></br>

        <label>Enter Soil EC</label>
        <input type='number' id="tdsbox" value={this.state.tds}
          onChange={e => { this.setState({ ec: e.target.value }); }} />
        <br></br>

        <label>Enter Soil Salinity</label>
        <input type='number' id="phbox" value={this.state.ph}
          onChange={e => { this.setState({ salinity: e.target.value }); }} />
        <br></br>

        <label>Enter Cycle</label>
        <input type='number' id="lightbox" value={this.state.light}
          onChange={e => { this.setState({ cycle: e.target.value }); }} />
        <br></br>

        <label>Enter Battery Voltage</label>
        <input type='number' id="waterlvlbox" value={this.state.waterLvl}
          onChange={e => { this.setState({ voltage: e.target.value }); }} />
        <br></br>

        <button id="addBtn" onClick={this.interface}>Add Data</button>
        <button id="updateBtn" onClick={this.interface}>Update Data</button>
        <button id="deleteBtn" onClick={this.interface}>Delete Data</button>
        <button id="selectBtn" onClick={this.interface}>Get Data from Database</button>
        <button onClick={this.startDataGenerator}>Start Data Generator</button>
        <button onClick={this.stopDataGenerator}>Stop Data Generator</button>
      </>
    )
  }

  interface(event) {
    const id = event.target.id;

    if (id === 'addBtn') {
      this.insertData();
    }

    else if (id === 'updateBtn') {
      this.updateData();
    }

    else if (id === 'deleteBtn') {
      this.deleteData();
    }

    else if (id === 'selectBtn') {
      this.selectData();
    }
  }

  generateRandomData=()=> {
    const temperature = (Math.random() * 15 + 20).toFixed(1);
    const humidity = (Math.random() * 15 + 70).toFixed(1);
    const tds = (Math.random() * 200 + 800).toFixed(1);
    const ph = (Math.random() * 3 + 5).toFixed(1);
    const light = (Math.random() * 50 + 50).toFixed(1);
    const waterLvl = (Math.random() * 5 + 10).toFixed(1);

    const { year, month, day, hours, minutes, seconds } = this.getCurrentDate();
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const monthName = monthNames[month - 1]; // Adjust for 0-based index
  
    const time = this.state.time !== '' ? this.state.time : `${hours}:${minutes}:${seconds}`;

    const randomData = {
      time,
      temperature,
      humidity,
      tds,
      ph,
      light,
      waterLvl,
      year,
      month : monthName,
      day
    };

    this.insertData(randomData);
  }

  startDataGenerator = () => {
    const intervalId = setInterval(this.generateRandomData, 10000);
    this.setState({ generatorIntervalId: intervalId });
  };

  stopDataGenerator = () => {
    const { generatorIntervalId } = this.state;
    if (generatorIntervalId) {
      clearInterval(generatorIntervalId);
      this.setState({ generatorIntervalId: null });
    }
  };

  getAllInputs() {
    const { year, month, day, hours, minutes, seconds } = this.getCurrentDate();
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const monthName = monthNames[month - 1]; // Adjust for 0-based index
  
    const time = this.state.time !== '' ? this.state.time : `${hours}:${minutes}:${seconds}`;
    
    return {
      time,
      temperature: this.state.temperature,
      humidity: this.state.humidity,
      tds: this.state.tds,
      ph: this.state.ph,
      light: this.state.light,
      waterLvl: this.state.waterLvl,
      year,
      month: monthName, // Replace the numeric month with the month name
      day
    };
  }
  
  

  insertData = (data) => {
    const db = this.state.db;

    set(ref(db, `${data.year}/${data.month}/${data.day}/${data.time}`),
      {
        Time: data.time,
        Temperature: data.temperature,
        Humidity: data.humidity,
        Tds: data.tds,
        Ph: data.ph,
        Light: data.light,
        WaterLvl: data.waterLvl,
      })
      // .then(() => {
      //   alert('Data was added successfully');
      //   // calculateAndSaveHighestHourlyValues(database, new Date());
      // })
      // .catch((error) => { alert('There was an error, details: ' + error) });

  }

  updateData() {
    const db = this.state.db;
    const data = this.getAllInputs();

    update(ref(db, `${data.year}/${data.month}/${data.day}/${data.time}`),
      {
        Time: data.time,
        Temperature: data.temperature,
        Humidity: data.humidity,
        Tds: data.tds,
        Ph: data.ph,
        Light: data.light,
        WaterLvl: data.waterLvl,
      })
      .then(() => { alert('Data was updated successfully') })
      .catch((error) => { alert('There was an error, details: ' + error) });

  }

  deleteData() {
    const db = this.state.db;
    const data = this.getAllInputs();
    const time = data.time;

    remove(ref(db, `${data.year}/${data.month}/${data.day}/${time}`))
      .then(() => { alert('Data was deleted successfully') })
      .catch((error) => { alert('There was an error, details: ' + error) })
  }

  selectData() {
    const db = this.state.db;
    const data = this.getAllInputs();
    const dbRef = ref(db, `${data.year}/${data.month}/${data.day}/${data.time}`);
    console.log("Year:", data.year);
    console.log("Month:", data.month);
    console.log("Day:", data.day);
    console.log("Time:", data.time);
   

    get(dbRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          this.setState({
            temperature: snapshot.val().Temperature,
            humidity: snapshot.val().Humidity,
            tds: snapshot.val().Tds,
            ph: snapshot.val().Ph,
            light: snapshot.val().Light,
            waterLvl: snapshot.val().WaterLvl,
          });
        } else {
          alert("No data found!");
        }
      })
      .catch((error) => {
        alert("There was an error: " + error);
      });
  }
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();


export default function Crud() {

  return(
    <Box ml="100px">
      <CrudSetup/>
    </Box>
  );
}
