// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";


const firebaseConfig = {
  apiKey: "AIzaSyDlmeTKJDiw7feTgK_zjvvSOtzxCRHhDz4",
  authDomain: "smart-farming-iot-58718.firebaseapp.com",
  databaseURL: "https://smart-farming-iot-58718-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "smart-farming-iot-58718",
  storageBucket: "smart-farming-iot-58718.appspot.com",
  messagingSenderId: "832525940681",
  appId: "1:832525940681:web:f6b869fc73427647e9da18"
};
// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Export the authentication instance
export const auth = getAuth(app);

// Export the database instance
export const database = getDatabase(app);

auth.onAuthStateChanged((user) => {
  if (user) {
    // User is signed in.
    const { displayName, email } = user;
    console.log("User's email:", email);
    console.log("User's name:", displayName);
  } else {
    // User is signed out.
  }
});

