import React, { useState, useEffect } from 'react';
import { database } from '../../components/firebaseConfig';
import { set, onValue, ref } from 'firebase/database';
import { Box, IconButton, useTheme, useMediaQuery, Tooltip, Popover } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [data, setData] = useState(null);

  const [autoData, setAutoData] = useState(null);
  const [deviceData, setDeviceData] = useState([]);
  const [checked, setChecked] = useState({});

  useEffect(() => {
    const deviceDataRef = ref(database, 'Device'); // Adjust the path as needed

    onValue(deviceDataRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setDeviceData(data);

        // Initialize the checked state based on the keys of your devices
        const initialChecked = {};
        Object.keys(data).forEach((key) => {
          initialChecked[key] = data[key];
        });
        setChecked(initialChecked);
      }
    });
  }, []);

  const handleChange = (key, isChecked) => {
    setChecked({ ...checked, [key]: isChecked });

    // Update the corresponding data in Firebase
    const dataRef = ref(database, `Device/${key}`); // Adjust the path as needed
    set(dataRef, isChecked);
  };


  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.toLocaleString('default', { month: 'long' });
    const day = currentDate.getDate().toString().padStart(2, '0');

    const dataRef = ref(database, `${year}/${month}/${day}`);

    const unsubscribe = onValue(dataRef, (snapshot) => {
      if (snapshot.exists()) {
        const dayData = snapshot.val();
        const latestTimestamp = Object.keys(dayData).pop();
        const latestData = dayData[latestTimestamp];

        setData(latestData);
      } else {
        setData(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const autoDataRef = ref(database, 'AutomationMin'); // not finish yet

    onValue(autoDataRef, (snapshot) => {
      if (snapshot.exists()) {
        const autoData = snapshot.val();
        setAutoData(autoData);
      }
    });
  }, []);

  // Function to toggle between dark and light mode
  const toggleThemeMode = () => {
    const newMode = theme.palette.mode === 'dark' ? 'light' : 'dark';
    colorMode.toggleColorMode(newMode);
  };

  useEffect(() => {
    if (
      data && autoData &&
      (data.Temperature > autoData.Temperature ||
      data.Humidity < autoData.Humidity ||
      data.Light < autoData.Light || 
      data.Ph < autoData.Ph ||
      data.WaterLvl < autoData.WaterLvl ||
      data.Tds > autoData.Tds)
    ) {
      const intervalId = setInterval(() => {
        // toggleThemeMode(); // on hold
      }, 2000);
      return () => clearInterval(intervalId);
    }
  }, [data]);

  useEffect(() => {
    if (data && autoData) {
      if (data.Light < autoData.Light) {
        const light01Ref = ref(database, 'Device/Light01');
        set(light01Ref, true);
      }
      if (data.Light > autoData.Light) {
        const light01Ref = ref(database, 'Device/Light01');
        set(light01Ref, false);
      }
      if (data.Humidity < autoData.Humidity) {
        const waterPump01Ref = ref(database, 'Device/WaterPump01');
        set(waterPump01Ref, true);
  
        const waterPump02Ref = ref(database, 'Device/WaterPump02');
        set(waterPump02Ref, true);
      }
      if (data.Humidity > autoData.Humidity) {
        const waterPump01Ref = ref(database, 'Device/WaterPump01');
        set(waterPump01Ref, false);
  
        const waterPump02Ref = ref(database, 'Device/WaterPump02');
        set(waterPump02Ref, false);
      }
      if (data.WaterLvl < autoData.WaterLvl) {
        const waterPump03Ref = ref(database, 'Device/WaterPump03');
        set(waterPump03Ref, true);
      }
      if (data.WaterLvl > autoData.WaterLvl) {
        const waterPump03Ref = ref(database, 'Device/WaterPump03');
        set(waterPump03Ref, false);
      }
    }
  }, [data, autoData]);
  

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
        marginLeft={isMobile ? "80px" : "80px"}
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box>

      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={toggleThemeMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <Tooltip title="Notifications">
          <IconButton>
            <NotificationsOutlinedIcon />
          </IconButton>
        </Tooltip>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>
        {/* <IconButton>
          <PersonOutlinedIcon />
        </IconButton> */}
      </Box>
    </Box>
  );
};

export default Topbar;
